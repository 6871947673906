/*#region paddingTop*/

.padding-top-10 {
    padding-top: 10px;
}

.padding-top-15 {
    padding-top: 15px;
}

.padding-top-20 {
    padding-top: 20px;
}

.padding-top-25 {
    padding-top: 25px;
}

.padding-top-35 {
    padding-top: 35px;
}

.padding-top-40 {
    padding-top: 40px;
}

.padding-top-50 {
    padding-top: 50px;
}

.padding-top-60 {
    padding-top: 60px;
}

.padding-top-80 {
    padding-top: 80px;
}

.padding-top-100 {
    padding-top: 100px;
}

/*#endregion paddingTop*/

/*#region paddingBottom*/
.padding-bottom-10 {
    padding-bottom: 10px;
}

.padding-bottom-15 {
    padding-bottom: 15px;
}

.padding-bottom-20 {
    padding-bottom: 20px;
}

.padding-bottom-25 {
    padding-bottom: 25px;
}

.padding-bottom-30 {
    padding-bottom: 30px;
}

.padding-bottom-35 {
    padding-bottom: 35px;
}

.padding-bottom-40 {
    padding-bottom: 40px;
}

.padding-bottom-50 {
    padding-bottom: 50px;
}

.padding-bottom-60 {
    padding-bottom: 60px;
}

.padding-bottom-80 {
    padding-bottom: 80px;
}

.padding-bottom-100 {
    padding-bottom: 100px;
}
/*#endregion paddingBottom*/

/*#region paddingY*/

.padding-y-10 {
    padding-top: 10px;
    padding-bottom: 10px;
}

.padding-y-15 {
    padding-top: 15px;
    padding-bottom: 15px;
}

.padding-y-20 {
    padding-top: 20px;
    padding-bottom: 20px;
}

.padding-y-25 {
    padding-top: 25px;
    padding-bottom: 25px;
}

.padding-y-30 {
	padding-top: 30px;
	padding-bottom: 30px;
}

.padding-y-35 {
    padding-top: 35px;
    padding-bottom: 35px;
}

.padding-y-40 {
    padding-top: 40px;
    padding-bottom: 40px;
}

.padding-y-50 {
    padding-top: 50px;
    padding-bottom: 50px;
}

.padding-y-60 {
    padding-top: 60px;
    padding-bottom: 60px;
}

.padding-y-80 {
    padding-top: 80px;
    padding-bottom: 80px;
}

.padding-y-100 {
    padding-top: 100px;
    padding-bottom: 100px;
}

/*#endregion paddingY*/

/*#region paddingX*/

.padding-x-10p {
    padding: 0 10%;
}

.padding-x-10 {
    padding: 0 10px;
}

.padding-x-15 {
    padding: 0 15px;
}

.padding-x-20 {
    padding: 0 20px;
}



/*#endregion paddingX*/

/*#region marginTop*/

.margin-top-0 {
    margin-top: 0;
}

.margin-top-10 {
    margin-top: 10px;
}

.margin-top-15 {
    margin-top: 15px;
}

.margin-top-20 {
    margin-top: 20px;
}


.margin-top-25 {
    margin-top: 25px;
}

.margin-top-30 {
    margin-top: 30px;
}

.margin-top-35 {
    margin-top: 35px;
}

.margin-top-40 {
    margin-top: 40px;
}

.margin-top-50 {
    margin-top: 50px;
}

.margin-top-60 {
    margin-top: 60px;
}

.margin-top-80 {
    margin-top: 80px;
}

.margin-top-100 {
    margin-top: 100px;
}

/*#endregion marginTop*/

/*#region marginBottom*/
.margin-bottom-10 {
    margin-bottom: 10px;
}

.margin-bottom-15 {
    margin-bottom: 15px;
}

.margin-bottom-20 {
    margin-bottom: 20px;
}

.margin-bottom-25 {
    margin-bottom: 25px;
}
.margin-bottom-30 {
    margin-bottom: 30px;
}

.margin-bottom-35 {
    margin-bottom: 35px;
}

.margin-bottom-40 {
    margin-bottom: 40px;
}

.margin-bottom-50 {
    margin-bottom: 50px;
}

.margin-bottom-60 {
    margin-bottom: 60px;
}

.margin-bottom-80 {
    margin-bottom: 80px;
}

.margin-bottom-100 {
    margin-bottom: 100px;
}
/*#endregion marginBottom*/

/*#region marginY*/

.margin-y-10 {
    margin-top: 10px;
    margin-bottom: 10px;
}

.margin-y-15 {
    margin-top: 15px;
    margin-bottom: 15px;
}

.margin-y-20 {
    margin-top: 20px;
    margin-bottom: 20px;
}

.margin-y-25 {
    margin-top: 25px;
    margin-bottom: 25px;
}

.margin-y-30 {
    margin-top: 30px;
    margin-bottom: 30px;
}


.margin-y-35 {
    margin-top: 35px;
    margin-bottom: 35px;
}

.margin-y-40 {
    margin-top: 40px;
    margin-bottom: 40px;
}

.margin-y-50 {
    margin-top: 50px;
    margin-bottom: 50px;
}

.margin-y-60 {
    margin-top: 60px;
    margin-bottom: 60px;
}

.margin-y-80 {
    margin-top: 80px;
    margin-bottom: 80px;
}

.margin-y-100 {
    margin-top: 100px;
    margin-bottom: 100px;
}

/*#endregion marginY*/

.white {
    color: #FFF;
}

.black {
    color: #000;
}

.container-bg-white {
    background-color: #fff;
}

.uppercase {
    text-transform: uppercase;
}

.relative {
    position: relative;
}

.display-inline-block {
    display: inline-block;
}

.display-block {
    display: block;
}

img.HoverBounce,
.HoverBounce img {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    -webkit-transition: -webkit-transform 0.5s ease-in-out;
    transition: -webkit-transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
    display: block;
    overflow: hidden;
}

    img.HoverBounce :hover,
    .HoverBounce img :hover {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
        -webkit-transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
                transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
        z-index: 100;
    }

a.HoverBounce {
    display: block;
}

    a.HoverBounce img {
        -webkit-transform: translateZ(0);
                transform: translateZ(0);
        -webkit-backface-visibility: hidden;
                backface-visibility: hidden;
        -webkit-transition: -webkit-transform 0.5s ease-in-out;
        transition: -webkit-transform 0.5s ease-in-out;
        transition: transform 0.5s ease-in-out;
        transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
        display: block;
        overflow: hidden;
    }

    a.HoverBounce :hover {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
        -webkit-transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
                transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
        z-index: 100;
    }

/*#region fonts*/
.OpenSans,
.BodyFont {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
}
.OpenSansSemiBold {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600 !important;
}
.OpenSansBold {
  font-family: 'Open Sans', sans-serif;
  font-weight: 700 !important;
}
.Coiny {
  font-family: 'Coiny', cursive;
  font-weight: 400 !important;
}
html {
  font-size: 16px;
}
body {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
}
.pageContent.textContent {
  font-size: 1rem;
}
@media (max-width: 767px) {
  .pageContent.textContent {
    text-align: center;
  }
}
.pageContent.textContent h1 {
  font-family: 'Coiny', cursive;
  font-weight: 400 !important;
  color: red;
  font-size: 3.125rem;
  margin-top: 0;
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .pageContent.textContent h1 {
    font-size: 2.5rem;
  }
}
.pageContent.textContent p {
  margin-bottom: 20px;
}
.lower-content {
  margin-top: 30px;
}
.lower-content h2 {
  font-family: 'Coiny', cursive;
  font-weight: 400 !important;
  color: red;
  font-size: 3.125rem;
  margin-top: 0;
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .lower-content h2 {
    font-size: 2.5rem;
  }
}
/*#endregion fonts*/
form {
  max-width: 100vw;
  overflow: hidden;
}
.outer-wrapper {
  max-width: 100vw;
  position: relative;
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/039/bg-3.png"), -webkit-gradient(linear, left top, left bottom, from(green), to(red));
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/039/bg-3.png"), linear-gradient(green, red);
  background-repeat: no-repeat, repeat, repeat;
  background-position: center top,center center;
}
.outer-wrapper .bg-2 {
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/039/service-bg.png");
  background-repeat: repeat;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.3;
  pointer-events: none;
}
.outer-wrapper--alt .main-content__container {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.outer-wrapper--alt .pageContent.textContent {
  text-align: center;
}
.container {
  position: relative;
}
@media (max-width: 767px) {
  .container {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .container {
    width: 100%;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}
.container--content {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}
/*#region topLinksMobile*/
#TopLinksMobile {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #f32c2d;
  background-color: #02631c;
  color: white;
  z-index: 20;
  padding: 0;
  border-bottom: 1px solid white;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
}
#TopLinksMobile .navbar {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  #TopLinksMobile {
    display: none !important;
  }
}
#TopLinksMobile #NavigationToggleText {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 14px 63px;
  text-align: right;
}
#TopLinksMobile .navbar-toggle .icon-bar {
  background: white;
}
#TopLinksMobile .row {
  padding-top: 10px;
}
#TopLinksMobile .input-group {
  margin-bottom: 0;
}
#MobileHomeLink {
  position: fixed;
  top: 10px;
  z-index: 10;
  background: transparent none repeat scroll 0 0;
  color: white;
  left: 10px;
  border-radius: 50%;
  border: 2px solid white;
}
/*#endregion topLinksMobile*/
.toplinks__nav {
  font-family: 'Coiny', cursive;
  font-weight: 400 !important;
  background: #f32c2d;
  border-radius: 10px 10px 0px 0px;
  color: white;
}
.toplinks__ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-bottom: 0;
  padding-left: 0;
}
.toplinks__li {
  position: relative;
  list-style: none;
  text-align: center;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}
.toplinks__li:first-child .toplinks__link {
  border-top-left-radius: 10px;
}
.toplinks__li:last-child .toplinks__link {
  border-top-right-radius: 10px;
}
.toplinks__li .dropdown-toggle:focus {
  text-decoration: none;
}
.toplinks__link {
  position: relative;
  display: block;
  padding: 6px 5px;
  color: #fff;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  font-size: 1.16rem;
  font-family: 'Coiny', cursive;
  font-weight: 400 !important;
  font-size: 25px;
}
.toplinks__link:hover,
.toplinks__link.active,
.toplinks__link:focus {
  background-color: #d62627;
  color: white;
}
.toplinks__dropdown {
  padding: 10px;
  margin: 0;
  border: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  text-align: center;
  background-color: #f32c2d !important;
}
.toplinks__dropdown-li {
  display: block;
}
.toplinks__dropdown-link {
  position: relative;
  padding: 5px 10px !important;
  font-size: 1.3rem;
  color: white !important;
}
.toplinks__dropdown-link:hover,
.toplinks__dropdown-link:focus,
.toplinks__dropdown-link.active {
  background-color: #d62627 !important;
  color: white !important;
}
.top-bar {
  background-color: #067323;
  padding: 10px 0;
}
@media (max-width: 767px) {
  .top-bar {
    display: none;
  }
}
.top-bar__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.top-bar__text {
  color: #d4e3d7;
  font-size: 1rem;
  text-transform: capitalize;
  display: inline-block;
}
.top-bar__text span {
  color: #fffc00;
}
.top-bar__link {
  background-color: #f8b800;
  border: 2px solid #f8b800;
  padding: 10px 15px;
  border-radius: 6px;
  text-decoration: none;
  color: #4b3a0a;
  text-transform: uppercase;
  display: inline-block;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600 !important;
  -webkit-transition: background 0.3s;
  transition: background 0.3s;
  white-space: nowrap;
  text-align: center;
}
.top-bar__link:hover {
  background: transparent;
  color: #f8b800;
}
.top-bar .SearchPanel {
  background: #fff;
  border-radius: 10px;
  height: 40px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin: 0 20px;
}
.top-bar .SearchPanel input {
  border: none;
  background: transparent;
  padding-left: 10px;
}
.top-bar .SearchPanel a {
  background: #fb1009;
  color: #fff;
  text-transform: uppercase;
  margin: 2px;
  border-radius: 10px;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: -webkit-box;
  display: flex;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 10px 15px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600 !important;
}
.header {
  padding: 50px 0 30px;
}
@media (max-width: 767px) {
  .header {
    padding-top: 80px;
  }
}
.header__inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
@media (min-width: 992px) {
  .header__inner {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
}
@media (min-width: 1200px) {
  .header__inner {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
}
@media (min-width: 1200px) {
  .header__container {
    padding: 0;
  }
}
.header__logo-panel {
  width: 100%;
  text-align: center;
  margin-bottom: 30px;
}
@media (min-width: 1200px) {
  .header__logo-panel {
    width: 25%;
    margin-bottom: 0;
  }
}
.header__info-panel {
  width: 100%;
}
@media (min-width: 1200px) {
  .header__info-panel {
    width: 70%;
  }
}
.header__phone-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
}
@media (min-width: 1200px) {
  .header__phone-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
}
.header__phone {
  max-width: 450px;
}
.header__weather {
  margin: 20px auto;
}
@media (min-width: 1200px) {
  .header__weather {
    margin: 0 10px;
  }
}
.header__social-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
@media (min-width: 768px) {
  .header__social-links {
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
  }
}
.header__social-link {
  margin: 0 5px;
  width: 50px;
}
@media (min-width: 1200px) {
  .header__social-link {
    width: 30px;
  }
}
.book-online {
  position: relative;
  background-color: #1fae37;
  border-radius: 12px;
  padding: 20px 45px;
  margin-top: 30px;
}
@media (max-width: 767px) {
  .book-online {
    padding-top: 35px;
  }
}
.book-online__title {
  margin: 0 auto 20px;
  display: block;
  max-width: 80% !important;
}
.book-online__calendar {
  position: absolute;
  left: -35px;
  top: 19%;
  width: 74px;
}
@media (max-width: 767px) {
  .book-online__calendar {
    display: none;
  }
}
@media (min-width: 1200px) {
  .book-online__calendar {
    position: absolute;
    left: -35px;
    top: 19%;
    width: 74px;
  }
}
.book-online__inner {
  position: relative;
}
@media (min-width: 768px) {
  .book-online__inner {
    height: 40px;
  }
}
.book-online #PostcodeSearchLeft select,
.book-online #PostcodeSearchLeft input,
.book-online .SearchDate,
.book-online .SearchCategory,
.book-online .SearchButton {
  height: 40px;
  border-radius: 10px;
  background-repeat: no-repeat;
  border-radius: 5px;
  padding-left: 10px;
}
@media (max-width: 767px) {
  .book-online #PostcodeSearchLeft select,
  .book-online #PostcodeSearchLeft input,
  .book-online .SearchDate,
  .book-online .SearchCategory,
  .book-online .SearchButton {
    display: block;
    width: 100%;
    margin: 0 auto 15px;
    max-width: 300px;
  }
}
@media (min-width: 768px) {
  .book-online #PostcodeSearchLeft select,
  .book-online #PostcodeSearchLeft input,
  .book-online .SearchDate,
  .book-online .SearchCategory,
  .book-online .SearchButton {
    position: absolute;
    top: 0;
    width: 25.8%;
  }
}
.book-online #PostcodeSearchLeft select::-webkit-input-placeholder,
.book-online #PostcodeSearchLeft input::-webkit-input-placeholder,
.book-online .SearchDate::-webkit-input-placeholder,
.book-online .SearchCategory::-webkit-input-placeholder,
.book-online .SearchButton::-webkit-input-placeholder {
  color: #000;
  opacity: 1;
}
.book-online #PostcodeSearchLeft select::-moz-placeholder,
.book-online #PostcodeSearchLeft input::-moz-placeholder,
.book-online .SearchDate::-moz-placeholder,
.book-online .SearchCategory::-moz-placeholder,
.book-online .SearchButton::-moz-placeholder {
  color: #000;
  opacity: 1;
}
.book-online #PostcodeSearchLeft select::-ms-input-placeholder,
.book-online #PostcodeSearchLeft input::-ms-input-placeholder,
.book-online .SearchDate::-ms-input-placeholder,
.book-online .SearchCategory::-ms-input-placeholder,
.book-online .SearchButton::-ms-input-placeholder {
  color: #000;
  opacity: 1;
}
.book-online #PostcodeSearchLeft select::placeholder,
.book-online #PostcodeSearchLeft input::placeholder,
.book-online .SearchDate::placeholder,
.book-online .SearchCategory::placeholder,
.book-online .SearchButton::placeholder {
  color: #000;
  opacity: 1;
}
@media (min-width: 768px) {
  .book-online #PostcodeSearchLeft select,
  .book-online #PostcodeSearchLeft input {
    left: 0;
  }
}
@media (min-width: 768px) {
  .book-online .SearchDate {
    left: 27.5%;
  }
}
@media (min-width: 768px) {
  .book-online .SearchCategory {
    left: 54.7%;
  }
}
.book-online .SearchButton {
  background: #f09831;
  font-family: 'Coiny', cursive;
  font-weight: 400 !important;
  padding-left: 0;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: -webkit-box;
  display: flex;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 22px;
  color: white;
}
@media (min-width: 768px) {
  .book-online .SearchButton {
    width: 17.9%;
    left: auto;
    right: 0;
  }
}
.banner #CarouselContainer {
  padding-top: 48.08%;
  overflow: hidden;
}
.banner #CarouselContainer .carousel-inner {
  position: absolute;
  top: 0;
  height: 100%;
}
.banner #CarouselContainer .carousel-inner .item {
  width: 100%;
}
.banner #CarouselContainer .carousel-inner .item a img,
.banner #CarouselContainer .carousel-inner .item img {
  width: 100%;
}
.banner__container {
  padding: 0;
}
.banner__kid {
  display: none;
}
@media (min-width: 1200px) {
  .banner__kid {
    z-index: 1;
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
    -webkit-transform: translate(90%, 60px);
            transform: translate(90%, 60px);
  }
}
.banner__kid img {
  display: block;
  position: relative;
}
.main-content__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.main-content__container {
  padding-top: 30px;
  padding-bottom: 30px;
}
@media (max-width: 767px) {
  .main-content__container {
    padding-top: 0;
  }
}
.main-content__left {
  position: relative;
}
.main-content__left-image-panel {
  position: absolute;
  bottom: 50px;
  right: 0;
}
@media (min-width: 1200px) {
  .main-content__left-image-panel {
    width: 600px;
  }
}
@media (max-width: 450px) {
  .page-listings > div {
    width: 100%;
  }
}
.page-listings__container {
  padding: 50px 30px;
  background: linear-gradient(45deg, #ffffff 0%, #f3f3f3 51%);
}
.page-listings__header {
  font-size: 20px;
  color: #02abe4;
  font-size: 3.4375rem;
  text-align: center;
  font-family: 'Coiny', cursive;
  font-weight: 400 !important;
  text-transform: capitalize;
  margin-top: 0;
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .page-listings__header {
    font-size: 2.5rem;
  }
}
.page-listings__kid-right {
  display: none;
}
@media (min-width: 1200px) {
  .page-listings__kid-right {
    display: block;
    position: absolute;
    top: 74px;
    right: -223px;
    z-index: 0;
    pointer-events: none;
  }
}
.castlePanel {
  width: 100%;
  margin: 0 0 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  border: none;
  border-radius: 15px 15px 0px 0px;
  background-color: transparent;
  text-align: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.castlePanel .SingleImagePanel {
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/039/castle-bg@2x.png");
  height: auto;
  border-radius: 15px 15px 15px 15px;
  overflow: hidden;
  padding-top: 0;
}
.castlePanel .SingleImagePanel:before {
  content: "";
  display: block;
  padding-top: 100%;
  width: 100%;
}
.castlePanel .SingleImagePanel img {
  position: absolute;
  top: 0 !important;
  left: 0 !important;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.castlePanel .castleTitlePanel {
  margin: 10px 0 0;
  padding: 0 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.castlePanel .castleTitlePanel a {
  color: #ff8200;
}
.castlePanel .castlePriceDefault,
.castlePanel .castlePriceDiscounted {
  padding: 0;
  margin-top: 10px;
  font-size: 1.5rem;
  color: #111;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600 !important;
  -ms-flex-item-align: center;
      align-self: center;
}
.castlePanel .castlePriceDefault span.oldprice,
.castlePanel .castlePriceDiscounted span.oldprice {
  font-size: 0.825rem;
}
.castlePanel .castlePriceDefault span.newprice,
.castlePanel .castlePriceDiscounted span.newprice {
  font-size: 1rem;
}
.castlePanel .castleCheckBook {
  border-radius: 7px;
  background-color: #cc1ef1;
  text-transform: uppercase;
  padding: 7px 15px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700 !important;
  font-size: 1rem;
  margin-top: 10px;
  height: auto;
  -ms-flex-item-align: center;
      align-self: center;
}
.services {
  margin-top: 50px;
}
.services__container {
  background-color: #ac1aff;
  padding: 50px 30px 0;
}
@media (min-width: 768px) {
  .services__container {
    border-radius: 10px;
  }
}
.services__intro {
  font-size: 1rem;
  text-align: center;
  color: #fff;
}
.services__intro h2 {
  margin-top: 0;
  margin-bottom: 30px;
  font-family: 'Coiny', cursive;
  font-weight: 400 !important;
  font-size: 2.5rem;
}
.services__inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  margin-top: 80px;
  margin-bottom: -50px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
@media (min-width: 768px) {
  .services__inner {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
}
.services__link {
  position: relative;
  padding: 0 15px 30px;
  border-radius: 15px;
  margin: 0 10px;
  text-align: center;
}
@media (max-width: 767px) {
  .services__link {
    width: 100%;
    max-width: 400px;
    margin: 0 auto 100px;
  }
}
@media (min-width: 768px) {
  .services__link {
    width: 30%;
  }
}
.services__link:nth-child(3n + 1) {
  background-color: #fec70c;
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/039/service-bg.png");
}
.services__link:nth-child(3n + 2) {
  background-color: #00b0ee;
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/039/service-bg.png");
}
.services__link:nth-child(3n + 3) {
  background-color: #e82a2a;
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/039/service-bg.png");
}
.services__image-panel {
  max-width: 50%;
  margin: -50px auto 10px;
  position: relative;
}
.services__image-panel:before {
  content: "";
  display: block;
  padding-top: 100%;
}
.services__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: center center;
     object-position: center center;
}
.services__title {
  color: #fff;
  font-family: 'Coiny', cursive;
  font-weight: 400 !important;
  font-size: 1.5rem;
  -webkit-text-stroke: 1px black;
  margin-bottom: 10px;
}
.services__description {
  color: #fff;
  font-size: 1rem;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600 !important;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
}
.ServiceSection {
  position: relative;
  height: 545px;
  width: 1290;
  margin-bottom: 100px;
}
@media (max-width: 767px) {
  .ServiceSection {
    height: 1220px;
    margin-bottom: 30px;
    height: 1410px;
  }
}
.ServiceSection h2 {
  text-align: center;
  color: white;
  font-size: 46px;
  font-family: 'Coiny', cursive;
  font-weight: 400 !important;
  padding-top: 67px;
}
@media (max-width: 767px) {
  .ServiceSection h2 {
    font-size: 36px;
  }
}
.ServiceSection__bodyText {
  color: white;
  font-size: 22px;
  text-align: center;
}
@media (max-width: 767px) {
  .ServiceSection__bodyText {
    margin: 10px auto;
    width: auto;
  }
}
@media (min-width: 768px) {
  .ServiceSection__bodyText {
    position: absolute;
    width: 71.16%;
    bottom: 61.83%;
    left: 14.88%;
  }
}
@media (max-width: 767px) {
  .ServiceSection__bodyText {
    padding-top: 10px;
    width: 90%;
    font-size: 17px;
  }
}
.ServiceSection__Services {
  padding: 166px 65px;
}
@media (max-width: 767px) {
  .ServiceSection__Services {
    padding: 164px 35px;
  }
}
.ServiceSection__Services__serviceHeader {
  font-family: 'Coiny', cursive;
  font-weight: 400 !important;
  color: #fff;
  text-align: center;
  font-size: 22px;
  position: relative;
  top: -50px;
  -webkit-text-stroke: 0.6px black;
}
@media (max-width: 767px) {
  .ServiceSection__Services__serviceHeader {
    top: -35px;
  }
}
.ServiceSection__Services__serviceBody {
  color: #fff;
  text-align: center;
  padding: 10px 20px;
  position: relative;
  top: -50px;
}
.ServiceSection__Services__serviceOne {
  background-size: cover;
  border-radius: 13px;
  margin-bottom: 70px;
  text-align: center;
}
.ServiceSection__Services__serviceOne img {
  position: relative;
  top: -65px;
}
@media (max-width: 767px) {
  .ServiceSection__Services__serviceOne img {
    position: relative;
    top: -39px;
    width: 75px;
  }
}
.ServiceSection__Services__serviceTwo {
  background-size: cover;
  border-radius: 13px;
  margin-bottom: 70px;
  text-align: center;
}
.ServiceSection__Services__serviceTwo img {
  position: relative;
  top: -65px;
}
@media (max-width: 767px) {
  .ServiceSection__Services__serviceTwo img {
    position: relative;
    top: -39px;
    width: 75px;
  }
}
.ServiceSection__Services__serviceThree {
  background-size: cover;
  border-radius: 13px;
  margin-bottom: 70px;
  text-align: center;
}
.ServiceSection__Services__serviceThree img {
  position: relative;
  top: -65px;
}
@media (max-width: 767px) {
  .ServiceSection__Services__serviceThree img {
    position: relative;
    top: -39px;
    width: 75px;
  }
}
.testimonials__container {
  background: #fff;
  padding: 50px;
  margin-top: 120px;
  margin-bottom: 120px;
  position: relative;
}
@media (min-width: 992px) {
  .testimonials__container {
    border-radius: 15px;
  }
}
.testimonials__inner {
  text-align: center;
}
.testimonials__logo {
  display: block;
  width: 100%;
  max-width: 400px !important;
  margin: 0 auto 60px;
}
@media (min-width: 992px) {
  .testimonials__logo {
    margin: 0 auto;
    max-width: 100% !important;
  }
}
.testimonials__quote {
  margin-bottom: 30px;
}
.testimonials .RotatorTestimonialContent {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
}
.testimonials time {
  display: none;
}
.testimonials a[itemprop="author"] {
  color: #7bba02;
  font-family: 'Coiny', cursive;
  font-weight: 400 !important;
  font-size: 20px;
}
.testimonials p[itemprop="reviewBody"] {
  text-align: center;
  color: blueviolet;
  font-family: 'Coiny', cursive;
  font-weight: 400 !important;
  font-size: 20px;
}
.testimonials__kids-left {
  display: none;
}
@media (min-width: 1200px) {
  .testimonials__kids-left {
    display: block;
    position: absolute;
    bottom: -100px;
    left: -150px;
    z-index: 1;
  }
}
.testimonials__kids-right {
  display: none;
}
@media (min-width: 1200px) {
  .testimonials__kids-right {
    display: block;
    position: absolute;
    bottom: -150px;
    right: -150px;
    z-index: 1;
  }
}
.testimonials #RotatorTestimonialsHeader {
  margin-bottom: 15px;
}
.testimonials #RotatorTestimonialsHeader p,
.testimonials #RotatorTestimonialsHeader a {
  text-align: center;
  color: #000;
  font-size: 1rem;
  position: relative;
}
.testimonials .RotatorTestimonialRating {
  max-width: 280px;
  margin: 0 auto;
}
.DetailsTitle h1,
.DetailsTitle h2,
.DetailsMainImage {
  background-color: #f32c2d;
  font-family: 'Coiny', cursive;
  font-weight: 400 !important;
}
.DetailsTitle h1,
.DetailsTitle h2 {
  padding: 10px;
}
.BookButtons {
  text-align: center;
}
.BookButtons .BookButton,
.BookButtons .CheckButton {
  width: 180px;
  margin-left: auto;
  float: none;
  margin: 0 10px 10px;
}
.footer-top {
  background-color: #171717;
  padding: 25px 0 40px;
}
.footer-top__title {
  font-family: 'Open Sans', sans-serif;
  font-weight: 700 !important;
  font-size: 1.5rem;
  color: lightgray;
  margin-bottom: 20px;
  text-align: center;
}
@media (min-width: 992px) {
  .footer-top__title {
    text-align: left;
  }
}
.footer-top a {
  color: lightgray;
  display: block;
  margin-bottom: 10px;
}
.footer-top a:hover {
  color: lightgray;
  text-decoration: underline;
}
.footer-top__links {
  text-align: center;
}
@media (max-width: 400px) {
  .footer-top__links {
    width: 100%;
    margin-bottom: 20px;
  }
}
@media (min-width: 992px) {
  .footer-top__links {
    text-align: left;
  }
}
.footer-top__logos {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
  margin-top: 25px;
}
@media (max-width: 767px) {
  .footer-top__logos {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
}
.footer-top__logo {
  margin: 0 10px;
}
@media (max-width: 767px) {
  .footer-top__logo {
    margin: 10px 10px;
  }
}
.footer-top__logo--rpii {
  width: 88px;
}
.footer-top__logo--tipe {
  width: 67px;
}
.footer-top__logo--bouncyCastleNetworkLink {
  width: 99px;
}
.footer-top__logo--biha {
  width: 67px;
}
.footer-top__logo--pipa {
  width: 100px;
}
.footer-top__phone {
  display: block;
  width: 100%;
  max-width: 450px;
  margin: 20px auto 0;
  text-align: center;
}
@media (min-width: 992px) {
  .footer-top__phone {
    max-width: none;
  }
}
.footer-bottom {
  background: #fff;
  padding: 30px 0 40px;
  font-size: 1rem;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600 !important;
}
.footer-bottom__email {
  display: inline-block;
  color: black;
  margin-bottom: 10px;
}
.footer-bottom__email a {
  color: #cc1ef1;
}
.footer-bottom__email a:hover {
  color: #cc1ef1;
}
.footer-bottom__copy {
  color: #595959;
}
.footer-bottom__bcn {
  max-width: 180px;
  display: inline-block;
  margin-top: 30px;
}
@media (min-width: 992px) {
  .footer-bottom__bcn {
    margin-top: 0;
  }
}
.footer-bottom__col {
  text-align: center;
}
@media (min-width: 992px) {
  .footer-bottom__col {
    text-align: left;
  }
}
@media (min-width: 992px) {
  .footer-bottom__col--bcn {
    text-align: right;
  }
}
/*#region Animations*/
.wow {
  visibility: hidden;
}
.fadeInUp2 {
  -webkit-animation-name: fadeInUp2;
          animation-name: fadeInUp2;
  -webkit-animation-timing-function: cubic-bezier(0.18, 0.89, 0.63, 1.47);
          animation-timing-function: cubic-bezier(0.18, 0.89, 0.63, 1.47);
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
}
@-webkit-keyframes fadeInUp2 {
  0% {
    -webkit-transform: translate3d(0, 200px, 0);
            transform: translate3d(0, 200px, 0);
  }
}
@keyframes fadeInUp2 {
  0% {
    -webkit-transform: translate3d(0, 200px, 0);
            transform: translate3d(0, 200px, 0);
  }
}
.animated {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fadeIn {
  -webkit-animation-name: fadeIn;
          animation-name: fadeIn;
}
@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}
@keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}
.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
          animation-name: fadeInLeft;
}
/*#endregion Animations*/
