@import "../Assets/Functions.less";
@import (inline) "../Assets/Utilities.css";
@cloudUrl: "https://files.bookingonline.co.uk/image/upload/f_auto/themes/039/";
@mobile: ~"(max-width: 767px)";
@tablet: ~"(min-width: 768px)";
@desktop: ~"(min-width: 1200px)";
@mobileNavColor: #f32c2d;
@castleDetailsColor: #f32c2d;
/*#region fonts*/
.OpenSans, .BodyFont {
	font-family: 'Open Sans', sans-serif;
	font-weight: 400;
}

.OpenSansSemiBold {
	font-family: 'Open Sans', sans-serif;
	font-weight: 600 !important;
}

.OpenSansBold {
	font-family: 'Open Sans', sans-serif;
	font-weight: 700 !important;
}

.Coiny {
	font-family: 'Coiny', cursive;
	font-weight: 400 !important;
}

html {
	font-size: 16px;
}

body {
	.BodyFont;
}

.nicefont {
}

.pageContent.textContent {
	font-size: 1rem;

	@media @mobile {
		text-align: center;
	}

	h1 {
		.Coiny;
		color: red;
		font-size: 3.125rem;
		margin-top: 0;
		margin-bottom: 30px;

		@media @mobile {
			font-size: 2.5rem;
		}
	}

	p {
		margin-bottom: 20px;
	}
}

.lower-content {
	margin-top:30px;
	h2 {
		.Coiny;
		color: red;
		font-size: 3.125rem;
		margin-top: 0;
		margin-bottom: 30px;

		@media @mobile {
			font-size: 2.5rem;
		}
	}
}

h2, h3, h4 {
}
/*#endregion fonts*/
form {
	max-width: 100vw;
	overflow: hidden;
}

.outer-wrapper {
	max-width: 100vw;
	position: relative;
	position: relative;
	background-image: url("@{cloudUrl}bg-3.png"),linear-gradient(green, red);
	background-repeat: no-repeat, repeat, repeat;
	background-position: center top,center center;

	.mainContent {
	}

	.bg-2 {
		background-image: url("@{cloudUrl}service-bg.png");
		background-repeat: repeat;
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0.3;
		pointer-events: none;
	}

	&--alt {
		.main-content__container {
			.border-top-radius(10px);
		}

		.pageContent.textContent {
			text-align: center;
		}
	}
}

.container {
	position: relative;

	@media @mobile {
		width: 100%;
	}

	@media @tablet {
		width: 100%;
	}

	@media @desktop {
		width: 1170px;
	}

	&--content {
		flex-grow: 1;
	}
}
/*#region topLinksMobile*/
#TopLinksMobile {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	background: @mobileNavColor;
	background-color: #02631c;
	color: white;
	z-index: 20;
	padding: 0;
	border-bottom: 1px solid white;
	.BodyFont();

	.navbar {
		margin-bottom: 0;
	}

	@media @tablet {
		display: none !important;
	}

	#NavigationToggleText {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		padding: 14px 63px;
		text-align: right;
	}

	.navbar-toggle .icon-bar {
		background: white;
	}

	.row {
		padding-top: 10px;
	}

	.input-group {
		margin-bottom: 0;
	}
}

#MobileHomeLink {
	position: fixed;
	top: 10px;
	z-index: 10;
	background: transparent none repeat scroll 0 0;
	color: white;
	left: 10px;
	border-radius: 50%;
	border: 2px solid white;
}
/*#endregion topLinksMobile*/
.toplinks {
	&__nav {
		.Coiny;
		background: #f32c2d;
		border-radius: 10px 10px 0px 0px;
		color: white;
	}

	&__ul {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		margin-bottom: 0;
		padding-left: 0;
	}

	&__li {
		position: relative;
		list-style: none;
		text-align: center;
		flex-grow: 1;

		&:first-child {
			.toplinks__link {
				border-top-left-radius: 10px;
			}
		}

		&:last-child {
			.toplinks__link {
				border-top-right-radius: 10px;
			}
		}

		.dropdown-toggle:focus {
			text-decoration: none;
		}
	}

	&__link {
		position: relative;
		display: block;
		padding: 6px 5px;
		color: #fff;
		transition: all 0.3s;
		font-size: 1.16rem;
		.Coiny;
		font-size: 25px;

		&:hover, &.active, &:focus {
			background-color: #d62627;
			color: white;
		}
	}

	&__dropdown {
		padding: 10px;
		margin: 0;
		border: none;
		box-shadow: none;
		text-align: center;
		background-color: #f32c2d !important;
	}

	&__dropdown-li {
		display: block;
	}

	&__dropdown-link {
		position: relative;
		padding: 5px 10px !important;
		font-size: 1.3rem;
		color: white !important;

		&:hover, &:focus, &.active {
			background-color: #d62627 !important;
			color: white !important;
		}
	}
}

.top-bar {
	background-color: #067323;
	padding: 10px 0;

	@media @mobile {
		display: none;
	}

	&__row {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&__text {
		color: #d4e3d7;
		font-size: 1rem;
		text-transform: capitalize;
		display: inline-block;

		span {
			color: #fffc00;
		}
	}

	&__link {
		background-color: #f8b800;
		border: 2px solid #f8b800;
		padding: 10px 15px;
		border-radius: 6px;
		text-decoration: none;
		color: #4b3a0a;
		text-transform: uppercase;
		display: inline-block;
		.OpenSansSemiBold;
		transition: background 0.3s;
		white-space: nowrap;
		text-align: center;

		&:hover {
			background: transparent;
			color: #f8b800;
		}
	}

	.SearchPanel {
		background: #fff;
		border-radius: 10px;
		height: 40px;
		position: relative;
		display: flex;
		justify-content: space-between;
		margin: 0 20px;

		input {
			border: none;
			background: transparent;
			padding-left: 10px;
		}

		a {
			background: #fb1009;
			color: #fff;
			text-transform: uppercase;
			margin: 2px;
			border-radius: 10px;
			.FlexCenter;
			padding: 10px 15px;
			.OpenSansSemiBold;
		}
	}
}

.header {
	padding: 50px 0 30px;

	@media @mobile {
		padding-top: 80px;
	}

	&__inner {
		display: flex;
		align-items:center;
		flex-direction: column;

		@media @md {
			flex-direction: row;
			flex-wrap: wrap;
		}

		@media @desktop {
			justify-content: space-between;
		}
	}

	&__container {
		@media @desktop {
			padding: 0;
		}
	}

	&__logo-panel {
		width: 100%;
		text-align: center;
		margin-bottom: 30px;

		@media @desktop {
			width: 25%;
			margin-bottom: 0;
		}
	}

	&__logo {
	}

	&__info-panel {
		width: 100%;

		@media @desktop {
			width: 70%;
		}
	}

	&__phone-row {
		display: flex;
		align-items: center;
		flex-direction: column-reverse;

		@media @desktop {
			flex-direction: row;
		}
	}

	&__phone {
		max-width: 450px;
	}

	&__weather {
		margin: 20px auto;

		@media @desktop {
			margin: 0 10px;
		}
	}

	&__social-links {
		display: flex;
		justify-content: center;

		@media @tablet {
			justify-content: flex-end;
		}
	}

	&__social-link {
		margin: 0 5px;
		width: 50px;

		@media @desktop {
			width: 30px;
		}
	}
}

.book-online {
	position: relative;
	background-color: #1fae37;
	border-radius: 12px;
	padding: 20px 45px;
	margin-top: 30px;

	@media @mobile {
		padding-top: 35px;
	}

	&__title {
		margin: 0 auto 20px;
		display: block;
		max-width: 80% !important;

		@media @sm {
		}
	}

	&__calendar {
		position: absolute;
		left: -35px;
		top: 19%;
		width: 74px;

		@media @mobile {
			display: none;
		}

		@media @desktop {
			position: absolute;
			left: -35px;
			top: 19%;
			width: 74px;
		}
	}

	&__inner {
		position: relative;

		@media @sm {
			height: 40px;
		}
	}

	#PostcodeSearchLeft select, #PostcodeSearchLeft input, .SearchDate, .SearchCategory, .SearchButton {
		height: 40px;
		border-radius: 10px;
		background-repeat: no-repeat;
		border-radius: 5px;
		padding-left: 10px;

		@media @mobile {
			display: block;
			width: 100%;
			margin: 0 auto 15px;
			max-width: 300px;
		}

		@media @sm {
			position: absolute;
			top: 0;
			width: 25.8%;
		}

		&::placeholder {
			color: #000;
			opacity: 1;
		}
	}

	#PostcodeSearchLeft select, #PostcodeSearchLeft input {
		@media @sm {
			left: 0;
		}
	}

	.SearchDate {
		@media @sm {
			left: 27.5%;
		}
	}

	.SearchCategory {
		@media @sm {
			left: 54.7%;
		}
	}

	.SearchButton {
		background: #f09831;
		.Coiny;
		padding-left: 0;
		.FlexCenter;
		font-size: 22px;
		color: white;

		@media @sm {
			width: 17.9%;
			left: auto;
			right: 0;
		}
	}
}

.banner {
	.BannerPanelSet(48.08%);

	&__container {
		padding: 0;
	}

	&__kid {
		display: none;

		@media @desktop {
			z-index: 1;
			display: block;
			position: absolute;
			right: 0;
			bottom: 0;
			transform: translate(90%,60px);
		}

		img {
			display: block;
			position: relative;
		}
	}
}

.main-content {
	&__row {
		display: flex;
	}

	&__container {
		padding-top: 30px;
		padding-bottom: 30px;

		@media @mobile {
			padding-top: 0;
		}
	}

	&__left {
		position: relative;
	}

	&__left-image-panel {
		position: absolute;
		bottom: 50px;
		right: 0;

		@media @desktop {
			width: 600px;
		}
	}
}

.page-listings {
	& > div {
		@media (max-width: 450px) {
			width: 100%;
		}
	}

	&__container {
		padding: 50px 30px;
		background: linear-gradient(45deg, rgba(255,255,255,1) 0%, rgba(243,243,243,1) 51%);
	}

	&__header {
		font-size: 20px;
		color: #02abe4;
		font-size: 3.4375rem;
		text-align: center;
		.Coiny;
		text-transform: capitalize;
		margin-top: 0;
		margin-bottom: 30px;

		@media @mobile {
			font-size: 2.5rem;
		}
	}

	&__kid-right {
		display: none;

		@media @desktop {
			display: block;
			position: absolute;
			top: 74px;
			right: -223px;
			z-index: 0;
			pointer-events: none;
		}
	}
}

.castlePanel {
	width: 100%;
	margin: 0 0 30px;
	display: flex;
	flex-direction: column;
	border: none;
	border-radius: 15px 15px 0px 0px;
	background-color: transparent;
	text-align: center;
	align-items: center;

	&:hover {
	}

	.SingleImagePanel {
		background-image: url("@{cloudUrl}castle-bg@2x.png");
		height: auto;
		border-radius: 15px 15px 15px 15px;
		overflow: hidden;
		padding-top: 0;

		&:before {
			content: "";
			display: block;
			padding-top: 100%;
			width: 100%;
		}

		img {
			position: absolute;
			top: 0 !important;
			left: 0 !important;
			right: 0;
			bottom: 0;
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}

	.castleTitlePanel {
		margin: 10px 0 0;
		padding: 0 5px;
		display: flex;
		justify-content: center;
		align-items: center;

		a {
			//font-size: 0.9rem;
			color: #ff8200;
		}
	}

	.castlePriceDefault, .castlePriceDiscounted {
		padding: 0;
		margin-top: 10px;
		font-size: 1.5rem;
		color: #111;
		.OpenSansSemiBold;
		align-self: center;

		span.oldprice {
			font-size: 0.825rem;
		}

		span.newprice {
			font-size: 1rem;
		}
	}

	span.oldprice, span.newprice {
	}

	.castleCheckBook {
		border-radius: 7px;
		background-color: #cc1ef1;
		text-transform: uppercase;
		padding: 7px 15px;
		.OpenSansBold;
		font-size: 1rem;
		margin-top: 10px;
		height: auto;
		align-self: center;
	}
}

.services {
	margin-top: 50px;

	&__container {
		background-color: #ac1aff;
		padding: 50px 30px 0;

		@media @sm {
			border-radius: 10px;
		}
	}

	&__intro {
		font-size: 1rem;
		text-align: center;
		color: #fff;

		h2 {
			margin-top: 0;
			margin-bottom: 30px;
			.Coiny;
			font-size: 2.5rem;
		}
	}

	&__inner {
		display: flex;
		justify-content: space-around;
		margin-top: 80px;
		margin-bottom: -50px;
		flex-direction: column;

		@media @sm {
			flex-direction: row;
		}
	}

	&__link {
		position: relative;
		padding: 0 15px 30px;
		border-radius: 15px;
		margin: 0 10px;
		text-align: center;

		@media @mobile {
			width: 100%;
			max-width: 400px;
			margin: 0 auto 100px;
		}

		@media @sm {
			width: 30%;
		}

		&:nth-child(3n + 1) {
			background-color: #fec70c;
			background-image: url("@{cloudUrl}service-bg.png");
		}

		&:nth-child(3n + 2) {
			background-color: #00b0ee;
			background-image: url("@{cloudUrl}service-bg.png");
		}

		&:nth-child(3n + 3) {
			background-color: #e82a2a;
			background-image: url("@{cloudUrl}service-bg.png");
		}
	}

	&__image-panel {
		max-width: 50%;
		margin: -50px auto 10px;
		position: relative;

		&:before {
			content: "";
			display: block;
			padding-top: 100%;
		}
	}

	&__image {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: contain;
		object-position: center center;
	}

	&__title {
		color: #fff;
		.Coiny;
		font-size: 1.5rem;
		-webkit-text-stroke: 1px black;
		margin-bottom: 10px;
	}

	&__description {
		color: #fff;
		font-size: 1rem;
		.OpenSansSemiBold;
		text-shadow: 1px 1px 1px rgba(0,0,0,0.5);
	}
}

.ServiceSection {
	position: relative;
	@width: 1290;
	@height: 545px;
	height: @height;
	width: @width;
	margin-bottom: 100px;

	@media @mobile {
		height: 1220px;
		margin-bottom: 30px;
		height: 1410px;
	}

	h2 {
		text-align: center;
		color: white;
		font-size: 46px;
		.Coiny;
		padding-top: 67px;

		@media @mobile {
			font-size: 36px;
		}
	}

	&__bodyText {
		color: white;
		.TopBarItem(918,@width,@height,auto,337,left,192);
		font-size: 22px;
		text-align: center;

		@media @mobile {
			padding-top: 10px;
			width: 90%;
			font-size: 17px;
		}
	}

	&__Services {
		padding: 166px 65px;

		@media @mobile {
			padding: 164px 35px;
		}

		&__serviceHeader {
			.Coiny;
			color: #fff;
			text-align: center;
			font-size: 22px;
			position: relative;
			top: -50px;
			-webkit-text-stroke: 0.6px black;

			@media @mobile {
				top: -35px;
			}
		}

		&__serviceBody {
			color: #fff;
			text-align: center;
			padding: 10px 20px;
			position: relative;
			top: -50px;
		}

		&__serviceOne {
			background-size: cover;
			border-radius: 13px;
			margin-bottom: 70px;
			text-align: center;

			img {
				position: relative;
				top: -65px;

				@media @mobile {
					position: relative;
					top: -39px;
					width: 75px;
				}
			}
		}

		&__serviceTwo {
			background-size: cover;
			border-radius: 13px;
			margin-bottom: 70px;
			text-align: center;

			img {
				position: relative;
				top: -65px;

				@media @mobile {
					position: relative;
					top: -39px;
					width: 75px;
				}
			}
		}

		&__serviceThree {
			background-size: cover;
			border-radius: 13px;
			margin-bottom: 70px;
			text-align: center;

			img {
				position: relative;
				top: -65px;

				@media @mobile {
					position: relative;
					top: -39px;
					width: 75px;
				}
			}
		}
	}
}

.testimonials {
	&__container {
		background: #fff;
		padding: 50px;
		margin-top: 120px;
		margin-bottom: 120px;
		position: relative;

		@media @md {
			border-radius: 15px;
		}
	}

	&__inner {
		text-align: center;
	}

	&__logo {
		display: block;
		width: 100%;
		max-width: 400px !important;
		margin: 0 auto 60px;

		@media @md {
			margin: 0 auto;
			max-width: 100% !important;
		}
	}

	&__quote {
		margin-bottom: 30px;
	}

	.RotatorTestimonialContent {
		display: flex;
		flex-direction: column-reverse;
	}

	time {
		display: none;
	}

	a[itemprop="author"] {
		color: #7bba02;
		.Coiny;
		font-size: 20px;
	}

	p[itemprop="reviewBody"] {
		text-align: center;
		color: blueviolet;
		.Coiny;
		font-size: 20px;
	}

	&__kids-left {
		display: none;

		@media @desktop {
			display: block;
			position: absolute;
			bottom: -100px;
			left: -150px;
			z-index: 1;
		}
	}

	&__kids-right {
		display: none;

		@media @desktop {
			display: block;
			position: absolute;
			bottom: -150px;
			right: -150px;
			z-index: 1;
		}
	}

	#RotatorTestimonialsHeader {
		margin-bottom: 15px;

		p, a {
			text-align: center;
			color: #000;
			font-size: 1rem;
			position: relative;
		}
	}

	.RotatorTestimonialRating {
		max-width: 280px;
		margin: 0 auto;
	}
}

.DetailsTitle h1, .DetailsTitle h2, .DetailsMainImage {
	background-color: @castleDetailsColor;
	.Coiny;
}

.DetailsTitle h1, .DetailsTitle h2 {
	padding: 10px;
}

.BookButtons {
	text-align: center;

	.BookButton, .CheckButton {
		width: 180px;
		margin-left: auto;
		float: none;
		margin: 0 10px 10px;
	}
}

.footer-top {
	background-color: #171717;
	padding: 25px 0 40px;

	&__title {
		.OpenSansBold;
		font-size: 1.5rem;
		color: lightgray;
		margin-bottom: 20px;
		text-align: center;

		@media @md {
			text-align: left;
		}
	}

	a {
		color: lightgray;
		display: block;
		margin-bottom: 10px;

		&:hover {
			color: lightgray;
			text-decoration: underline;
		}
	}

	&__links {
		text-align: center;

		@media (max-width:400px) {
			width: 100%;
			margin-bottom: 20px;
		}

		@media @md {
			text-align: left;
		}
	}

	&__logos {
		display: flex;
		justify-content: center;
		align-items: baseline;
		margin-top: 25px;

		@media @mobile {
			flex-wrap: wrap;
		}
	}

	&__logo {
		margin: 0 10px;

		@media @mobile {
			margin: 10px 10px;
		}

		&--rpii {
			width: 88px;
		}

		&--tipe {
			width: 67px;
		}

		&--bouncyCastleNetworkLink {
			width: 99px;
		}

		&--biha {
			width: 67px;
		}

		&--pipa {
			width: 100px;
		}
	}

	&__phone {
		display: block;
		width: 100%;
		max-width: 450px;
		margin: 20px auto 0;
		text-align: center;

		@media @md {
			max-width: none;
		}
	}
}

.footer-bottom {
	background: #fff;
	padding: 30px 0 40px;
	font-size: 1rem;
	.OpenSansSemiBold;

	&__email {
		display: inline-block;
		color: black;
		margin-bottom: 10px;

		a {
			color: #cc1ef1;

			&:hover {
				color: #cc1ef1;
			}
		}
	}

	&__copy {
		color: #595959;
	}

	&__bcn {
		max-width: 180px;
		display: inline-block;
		margin-top: 30px;

		@media @md {
			margin-top: 0;
		}
	}

	&__col {
		text-align: center;

		@media @md {
			text-align: left;
		}

		&--bcn {
			@media @md {
				text-align: right;
			}
		}
	}
}
/*#region Animations*/
.wow {
	visibility: hidden;
}

.fadeInUp2 {
	animation-name: fadeInUp2;
	animation-timing-function: cubic-bezier(.18,.89,.63,1.47);
	animation-duration: 1s;
}

@keyframes fadeInUp2 {
	0% {
		transform: translate3d(0, 200px,0);
	}

	100% {
	}
}

.animated {
	animation-duration: 1s;
	animation-fill-mode: both
}

@keyframes fadeIn {
	0% {
		opacity: 0
	}

	to {
		opacity: 1
	}
}

.fadeIn {
	animation-name: fadeIn
}

@keyframes fadeInLeft {
	0% {
		opacity: 0;
		transform: translate3d(-100%,0,0)
	}

	to {
		opacity: 1;
		transform: none
	}
}

.fadeInLeft {
	animation-name: fadeInLeft
}

/*#endregion Animations*/
